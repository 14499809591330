import { render, staticRenderFns } from "./EmptySearch.vue?vue&type=template&id=11ff6c40&scoped=true&functional=true&"
import script from "./EmptySearch.vue?vue&type=script&lang=js&"
export * from "./EmptySearch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "11ff6c40",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/CODE/pre/multiplatform/asterix/20231011165930Z/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('11ff6c40')) {
      api.createRecord('11ff6c40', component.options)
    } else {
      api.rerender('11ff6c40', component.options)
    }
    module.hot.accept("./EmptySearch.vue?vue&type=template&id=11ff6c40&scoped=true&functional=true&", function () {
      api.rerender('11ff6c40', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/icons/EmptySearch.vue"
export default component.exports